$primaryColor: #1068ec;
$primaryColor50: #dbe8fc;
$primaryColor100: #abcaf8;
$primaryColor500: #1068ec;
$sucessColor500: #17e15c;
$sucessColor900: #009934;
$sucessColor50: #dcfbe7;

$warningColor500: #fc8518;
$warningColor100: #fed4ae;
$warningColor50: #ffeddc;
$errorColor500: #ec1010;
$errorColor50: #fcdbdb;
$UI-900: #00112b;
$UI-50: #ffffff;
$primaryColor900: #002459;
$neutralColor700: #69778c;
$neutralColor500: #8f9db2;
$neutralColor300: #a3aebf;
$neutralColor100: #d8e0ec;
$neutralColor50: #f3f6fb;
$standardFontSize: 12px;
$sideMenuUserDataWrapperHeight: 100px;
$sideMenuBackgroundColor: $neutralColor50;
$sideMenuLogoHeight: 78px;
$primaryColor700: #0545a6;
$primaryColor300: #5895f2;
$minHeightTitle: 86px;

@import url('./buttons.scss');
@import url('./inputs.scss');
@import url('./redesignMobile.scss');
@import url('../icomon/style.css');
@import url('./erpStyle.scss');

@mixin custom-scrollbar {
    ::-webkit-scrollbar {
        height: 4px;
        width: 4px;
        background: $neutralColor50;
    }

    ::-webkit-scrollbar-thumb {
        background: $neutralColor700;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(115, 115, 115, 0.75);
    }

    ::-webkit-scrollbar-corner {
        background: rgb(81, 81, 81);
    }
}

* {
    @include custom-scrollbar();
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin menu-item-hovered {
    background-color: $neutralColor100;
}

@mixin regular12Font {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

@mixin regular10Font {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
}

@mixin regular14Font {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

@mixin regular16Font {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}

.font-9p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 10px;
}

.font-12p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}
@mixin regular20Font {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
}

.font-14p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
}

.font-12p {
    @include regular12Font();
}

.font-11p {
    font-family: 'Work Sans';
    font-style: normal;
    font-size: 11px;
    line-height: 16px;
    color: #1068ec;
}

.font-14p-normal {
    @include regular14Font();
    line-height: 20px;
    font-weight: normal !important;
    color: $UI-900;
    line-height: 16px;
}

.font-12p-normal {
    @include regular12Font();
    line-height: 20px;
    font-weight: normal !important;
    color: $UI-900;
}

.font-14p-light {
    @include regular14Font();
    line-height: 20px;
    font-weight: normal !important;
    color: $neutralColor300;
}

.font-16p {
    @include regular16Font();
}

.font-16p-spacing {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 2px;
}

.font-20p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
}

.font-16p-light {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: $neutralColor300;
}

.font-20p-light {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    color: $neutralColor300;
}

.font-40p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 25px;
}

// @font-face {
//     font-family: 'Work Sans';
//     font-style: normal;
//     font-weight: 200;
//     src: url('./fonts/work-sans-v18-latin-200.eot');
//     /* IE9 Compat Modes */
//     src: local(''), url('./fonts/work-sans-v18-latin-200.eot?#iefix') format('embedded-opentype'),
//         /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-200.woff2') format('woff2'),
//         /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-200.woff') format('woff'),
//         /* Modern Browsers */ url('./fonts/work-sans-v18-latin-200.ttf') format('truetype'),
//         /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-200.svg#WorkSans') format('svg');
//     /* Legacy iOS */
// }

// /* work-sans-regular - latin */
// @font-face {
//     font-family: 'Work Sans';
//     font-style: normal;
//     font-weight: 400;
//     src: url('./fonts/work-sans-v18-latin-regular.eot');
//     /* IE9 Compat Modes */
//     src: local(''), url('./fonts/work-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
//         /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-regular.woff2') format('woff2'),
//         /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-regular.woff') format('woff'),
//         /* Modern Browsers */ url('./fonts/work-sans-v18-latin-regular.ttf') format('truetype'),
//         /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-regular.svg#WorkSans') format('svg');
//     /* Legacy iOS */
// }

// /* work-sans-500 - latin */
// @font-face {
//     font-family: 'Work Sans';
//     font-style: normal;
//     font-weight: 500;
//     src: url('./fonts/work-sans-v18-latin-500.eot');
//     /* IE9 Compat Modes */
//     src: local(''), url('./fonts/work-sans-v18-latin-500.eot?#iefix') format('embedded-opentype'),
//         /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-500.woff2') format('woff2'),
//         /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-500.woff') format('woff'),
//         /* Modern Browsers */ url('./fonts/work-sans-v18-latin-500.ttf') format('truetype'),
//         /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-500.svg#WorkSans') format('svg');
//     /* Legacy iOS */
// }

// /* work-sans-600 - latin */
// @font-face {
//     font-family: 'Work Sans';
//     font-style: normal;
//     font-weight: 600;
//     src: url('./fonts/work-sans-v18-latin-600.eot');
//     /* IE9 Compat Modes */
//     src: local(''), url('./fonts/work-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'),
//         /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-600.woff2') format('woff2'),
//         /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-600.woff') format('woff'),
//         /* Modern Browsers */ url('./fonts/work-sans-v18-latin-600.ttf') format('truetype'),
//         /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-600.svg#WorkSans') format('svg');
//     /* Legacy iOS */
// }

// /* work-sans-italic - latin */
// @font-face {
//     font-family: 'Work Sans';
//     font-style: italic;
//     font-weight: 400;
//     src: url('./fonts/work-sans-v18-latin-italic.eot');
//     /* IE9 Compat Modes */
//     src: local(''), url('./fonts/work-sans-v18-latin-italic.eot?#iefix') format('embedded-opentype'),
//         /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-italic.woff2') format('woff2'),
//         /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-italic.woff') format('woff'),
//         /* Modern Browsers */ url('./fonts/work-sans-v18-latin-italic.ttf') format('truetype'),
//         /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-italic.svg#WorkSans') format('svg');
//     /* Legacy iOS */
// }
/* work-sans-200 - latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 200;
    src: url('./fonts/work-sans-v18-latin-ext-200.eot'); /* IE9 Compat Modes */
    src: url('./fonts/work-sans-v18-latin-ext-200.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-ext-200.woff2') format('woff2'),
        /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-200.woff') format('woff'),
        /* Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-200.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-ext-200.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-200italic - latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 200;
    src: url('./fonts/work-sans-v18-latin-ext-200italic.eot'); /* IE9 Compat Modes */
    src: url('./fonts/work-sans-v18-latin-ext-200italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-ext-200italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-200italic.woff') format('woff'),
        /* Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-200italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-ext-200italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-regular - latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/work-sans-v18-latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: url('./fonts/work-sans-v18-latin-ext-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-ext-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-regular.woff') format('woff'),
        /* Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-ext-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-italic - latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 400;
    src: url('./fonts/work-sans-v18-latin-ext-italic.eot'); /* IE9 Compat Modes */
    src: url('./fonts/work-sans-v18-latin-ext-italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-ext-italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-italic.woff') format('woff'),
        /* Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-ext-italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500 - latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/work-sans-v18-latin-ext-500.eot'); /* IE9 Compat Modes */
    src: url('./fonts/work-sans-v18-latin-ext-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-ext-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-500.woff') format('woff'),
        /* Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-ext-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500italic - latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 500;
    src: url('./fonts/work-sans-v18-latin-ext-500italic.eot'); /* IE9 Compat Modes */
    src: url('./fonts/work-sans-v18-latin-ext-500italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-ext-500italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-500italic.woff') format('woff'),
        /* Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-500italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-ext-500italic.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/work-sans-v18-latin-ext-600.eot'); /* IE9 Compat Modes */
    src: url('./fonts/work-sans-v18-latin-ext-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('./fonts/work-sans-v18-latin-ext-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-600.woff') format('woff'),
        /* Modern Browsers */ url('./fonts/work-sans-v18-latin-ext-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('./fonts/work-sans-v18-latin-ext-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}

@mixin bold-letters {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
}

*,
html,
body,
#nabvar-brand {
    font-family: 'Work Sans';
}

.about-logo {
    align-items: center;
    justify-content: center;
    svg {
        width: 200px;
        height: 60px;
    }
}
.about-second-wrapper {
    position: absolute;
    min-height: 40%;
    height: 40%;
    width: 20%;
    margin-top: 10%;
    margin-left: 50%;
    float: right;
    background-color: $UI-50;
}

.login-wrapper {
    margin: 0;
    min-height: 100vh;
    height: 100vh;
    display: flex;
}

.login-background {
    background-image: url('../img/hotelLogin.svg');
    // background-repeat: no-repeat;
    // background-size: cover;
    // position: absolute;
    // min-height: 100%;
    // height: 100%;
    // width: 50%;
    // margin-top: 0;
    flex: 1;
}

.login-form-wrapper {
    // position: absolute;
    // min-height: 100%;
    // height: 100%;
    // width: 50%;
    // margin-top: 0;
    // margin-left: 50%;
    // float: right;
    background-color: $neutralColor50;
    flex: 1;
}

.login-logo {
    position: absolute;
    align-items: center;
    z-index: 9999;
    overflow: hidden;
    width: 40%;
    padding-left: 70px;
    top: 100%;
    height: 100px;
    transform: translateY(-100%);

    .logo {
        background-image: url('../custom/logo/logo_white.png');
        background-repeat: no-repeat;
        background-size: contain;
        // width: 300px;
        height: 60px;
    }

    svg {
        width: 250px;
        height: 100px;
        path {
            fill: $UI-50;
        }
    }
}

.login-bg-white {
    border-radius: 6px;
    // box-shadow: 0px 1px 2px rgba(115, 115, 115, 0.75);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    width: 385px;
    height: 550px;

    .form-wrapper {
        background-color: $UI-50;
        .form-container {
            margin-left: 35px;
            margin-right: 35px;
            height: 550px;
        }
        border: 1px solid $neutralColor100;
        border-radius: 10px;
    }
}

.logo-only {
    padding-top: 35px;
    svg {
        width: 40px;
        height: 40px;
    }
}

.copyright {
    position: absolute;
    bottom: 0;
    padding-left: 30px;
    padding-top: 15px;
    height: 50px;
}

.hr-span {
    text-align: center;
    border-top: 1px solid lightgray;
    width: 100%;
    overflow: visible;
}

.hr-span:after {
    content: 'or';
    padding: 0 4px;
    position: relative;
    top: -10px;
    background-color: $UI-50;
}

.center-text {
    text-align: center;
}

.alert-box {
    text-align: center;
    border-radius: 6px;
    background-color: rgba(236, 16, 16, 0.4);
    padding: 4px;
    font-size: 12px;
    font-weight: bold;
}

.primary-color {
    color: $primaryColor500;
}

span,
label {
    @include regular12Font();
}

.app-flex-div {
    background-color: white;
}

.flex-center-center {
    @include flex-center();
}

.flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-center-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-start {
    display: flex;
    align-items: flex-start;
}

.flex-center-left {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: normal;
}

.flex-center-right {
    display: flex;
    justify-content: right;
    align-items: center;
    font-weight: normal;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
}

.flex-left-column {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
}

h1 {
    // font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
}

h2 {
    font-size: 23px;
}

h3 {
    font-size: 19px;
}

.h3-bold {
    font-weight: 400;
    font-size: 19px;
}

.h3-chm {
    font-weight: 900;
    font-size: 20px;
}

.p-text {
    font-size: 12px;
    color: $neutralColor300;
}

.p-text-small {
    font-size: 9px;
    color: $neutralColor300;
}

.side-menu-category {
    min-height: 32px;
    width: 100%;
    @include flex-center();
    flex-direction: column;
    color: $UI-900;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    svg {
        min-width: 32px;
        min-height: 32px;
        // margin-left: 8px;
        margin-right: 4px;
    }

    div {
        width: 100%;
    }
}

.side-menu-user-data-wrapper {
    min-height: $sideMenuUserDataWrapperHeight;
    height: $sideMenuUserDataWrapperHeight;
    position: absolute;
    width: 100%;
    z-index: 10;
    background-color: $sideMenuBackgroundColor;
    bottom: 0;

    .horizontal-line {
        height: 1px;
        background-color: $neutralColor100;
        margin: 0 8px;
        z-index: 1;
    }

    .side-menu-user-data-center-wrapper {
        margin: -1px;
        font-size: $standardFontSize;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;

        // @include flex-center();
        .side-menu-user-data-center {
            width: 100%;
            // div {
            @include flex-center();
            height: 64px;
            margin: 0 8px;

            div {
                display: flex;
                justify-content: left;
                align-items: center;
                margin-left: 10px;
                width: 100%;
            }
        }
    }

    .circle {
        background: $UI-900;
        color: #fff;
        border-radius: 50%;
        font-size: 14px;
        width: 30px;
        min-width: 30px;
        height: 30px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}

.category-item {
    cursor: pointer;

    div {
        margin: 0 8px;
        border-radius: 6px;

        &:hover {
            @include menu-item-hovered();
        }

        justify-content: space-between;
    }
}

.side-menu-category > div {
    width: 100%;
    display: flex;
    align-items: center;

    div {
        display: flex;
        align-items: center;
    }
}

.side-menu-subitem-list > div {
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 48px;
    cursor: pointer;
    margin: 0 8px;

    &:hover {
        background-color: $neutralColor100;
    }
}

.custom-list-item-selected {
    background-color: $neutralColor50;
}

.custom-list-item.noHover {
    &:hover {
        background-color: #fff;
    }
}

.menu-item-selected {
    color: $primaryColor500;
    background-color: $neutralColor100;
    font-weight: 600 !important;

    svg path {
        fill: $primaryColor500;
    }

    span,
    label {
        font-weight: 600 !important;
    }
    .font-16p {
        font-size: 16px !important;
    }
}

.font-16p {
    font-size: 16px !important;
}

.category-item-selected {
    color: $primaryColor500;
    background-color: $primaryColor50;
    margin: 0 8px;
    @include bold-letters();
}

.side-menu-subitem-list {
    // width: 100%;
    @include flex-center();
    flex-direction: column;

    div {
        width: calc(100% - 16px);
        border-radius: 10px;
        padding-left: (48px - 4px);
    }
}

* {
    box-sizing: border-box;
}

.scrollbar-custom {
    @include custom-scrollbar();
}

.full-width-overflowX {
    width: 100%;
    overflow-x: auto;
    flex: 1;
}

.mobile-page {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#myMainMenu {
    user-select: none;
    box-sizing: content-box;
    @include custom-scrollbar();

    background-color: $neutralColor50;
    position: relative;
    // height: 100%;
    // min-width: 200px;
    min-width: 246px;
    margin: 10px 10px 10px 10px;
    border: 1px solid;
    border-color: $neutralColor100;
    border-radius: 6px;
    overflow-y: auto;
    overflow: hidden;

    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    .hotel-name {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 4px 0;
    }

    .side-menu-logo {
        // position:absolute;
        // top:0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $sideMenuBackgroundColor;
        z-index: 10;
        overflow: hidden;
        width: 100%;
        // margin-top: 24px;
        padding-left: 15px;
        // margin-bottom: 24px;
        height: 78px;

        img {
            margin-left: 8px;
            margin-right: 8px;
            border-radius: 6px;

            &:hover {
                @include menu-item-hovered();
            }
        }
        svg {
            width: 160px;
        }

        h3 {
            margin-bottom: 0;
        }
    }
    .user-email-data {
        cursor: pointer;
        padding: 2px;
        &:hover {
            background-color: $neutralColor100;
            border-radius: 6px;
        }
    }
}

#myMainMenu.hidden {
    width: 48px !important;
    max-width: 48px !important;
    min-width: 48px !important;

    .side-menu-category {
        .item-label {
            display: none;
        }
    }

    .user-email-data,
    .user-more-actions {
        display: none;
    }
    .hide-on-menu-hidden {
        display: none;
    }

    .category-item > div {
        width: 32px;
    }

    .side-menu-logo {
        padding: 0;
        justify-content: center;

        h3,
        svg {
            display: none !important;
        }
    }
    .hotel-name {
        display: none;
    }

    #animatethis {
        overflow-x: hidden;
    }

    .category-item.menu-item-selected svg {
        border: 1px solid transparent;
        border-radius: 6px;
        color: $primaryColor500;
        background-color: $primaryColor50;

        path {
            fill: $primaryColor500;
        }
    }

    .category-item.menu-item-selected {
        background-color: transparent;
    }
}

#animatethis {
    overflow-y: auto;
    height: calc(100% - 178px);
    z-index: 2;
}

.checkbox-with-label-wrapper {
    @include flex-center();

    label {
        margin-bottom: 0;
        padding: 2px;
        cursor: pointer;
    }
}
.checkbox-with-label-wrapper-left {
    .checkbox-with-label-wrapper {
        justify-content: left;
        margin: 0 0 0 12px;
        label {
            margin-left: 4px;
        }
    }
}

.checkbox-primary {
    color: $UI-50;
    width: 12px;
    height: 12px;
    max-width: 12px !important;
    max-height: 12px !important;
    background-color: $primaryColor500;

    &:hover {
        color: $UI-50;
        background-color: $primaryColor700;
    }

    &:active {
        padding: 7px 11px;
    }

    &:focus,
    &:focus-visible {
        color: $UI-50;
        background-color: $primaryColor500;
        border: 2px solid $primaryColor900;
    }

    &:disabled {
        color: $UI-50;
        background-color: $neutralColor300;
    }
}

.checkbox-success {
    color: $UI-50;
    width: 14px;
    height: 14px;
    max-width: 14px !important;
    max-height: 14px !important;
    accent-color: $sucessColor500;

    &:hover {
        color: $UI-50;
        accent-color: $sucessColor500;
    }

    &:active {
        padding: 7px 11px;
    }

    &:focus,
    &:focus-visible {
        color: $UI-50;
        accent-color: $sucessColor500;
        border: 2px solid $sucessColor500;
    }

    &:disabled {
        color: $UI-50;
        background-color: $neutralColor300;
    }
}

.input {
    background: #ffffff;
    border: 1px solid $neutralColor100;
    border-radius: 6px;
    height: 32px;
    width: 187px;
    padding: 8px 16px;
    @include regular12Font();

    &:hover {
        border: 1px solid $neutralColor300;
    }

    &:active,
    &:focus-within {
        border: 1px solid $primaryColor500;
    }

    &:focus,
    &:focus-visible {
        border: 1px solid $UI-900;
        outline: none;
    }

    &:disabled {
        border: 1px solid $neutralColor100;
        background-color: $neutralColor50;
    }
}

.input-modal {
    width: 100%;
}

.input-modal[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

.is-invalid {
    border-color: 1px solid #dc3545 !important;
    // padding-right: calc(1.5em + 0.75rem);
    // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    // background-repeat: no-repeat;
    // background-position: center right calc(0.375em + 0.1875rem);
    // background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid-warning {
    border-color: 1px solid #fcdf90 !important;
}

input[type='number'].input {
    padding-right: 6px;
    padding-left: 8spx;
}

.input-container {
    display: flex;
    align-items: center;
    /* IE10 */
    width: 230px;
    // height: 32px;
    border: 1px solid $neutralColor100;
    border-radius: 6px;
    background-color: white;
    @include regular12Font();

    &:hover {
        border: 1px solid $neutralColor300;
    }

    &:active,
    &:focus-within {
        border: 1px solid $primaryColor500;
    }

    &:focus,
    &:focus-visible {
        border: 1px solid $UI-900;
        outline: none;
    }

    &:disabled {
        border: 1px solid $neutralColor50;
    }

    img {
        height: 32px;
        width: 32px;
        min-height: 32px;
        min-width: 32px;
    }

    svg path {
        fill: $neutralColor700;
    }

    svg {
        min-height: 32px;
        min-width: 32px;
    }

    input {
        background: #ffffff;
        border-radius: 6px;
        border: none;
        outline: none;
        height: 100%;
        width: 187px;
        padding: 8px 16px;
    }

    .input-field {
        width: 100%;
        padding: 8px 16px 8px 16px;
    }

    .input-field-with-icon {
        width: 100%;
        padding: 4px 16px 4px 0;
    }
}

// ---------------------------------dashboard---------------------------

.dashboard-filters-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 78px;
    align-items: center;

    .checkbox-primary {
        margin: 0 4px 0 0;
    }

    .dashboard-filters {
        display: flex;
        justify-content: flex-end;
    }

    .flex-center-center {
        padding: 0 4px;
        .input-container {
            min-height: 32px;
        }
    }
}

.splitButton-reportToolbar-search-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hw-room-tile {
    padding: 2px;
    border-radius: 6px;
    background-color: #f3f6fb !important;
}

.reservation-filters-wrapper {
    height: 48px;
    background-color: $neutralColor50;
    border: 1px solid $neutralColor100;
    border-radius: 6px;
    display: flex;
    align-items: center;

    .input,
    .button-light,
    .button-primary,
    .checkbox-with-label-wrapper {
        margin: 0 6px;
    }
}

.reservation-filters-wrapper > span {
    margin-left: 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
}

.reservation-status-checkboxes-wrapper {
    height: auto;
    min-height: 32px;
    margin-top: 30px;
    border: 1px solid $neutralColor100;
    display: inline-block;
    border-radius: 6px;

    .reservation-status-checkboxes {
        display: flex;

        div {
            padding: 8px;
        }

        align-items: center;
    }
}

.page-title-button {
    margin-right: 20px;
    padding-top: 24px;
}

.page-title {
    padding-left: 20px;
    padding-top: 24px;
    padding-bottom: 34px;
    position: relative;
}

.financial-journal-subtitle {
    padding-left: 25px;
    padding-top: 5px;
    padding-bottom: 34px;
    position: relative;
    font-size: 14px;
    font-style: italic;
}
// }

.display-flex {
    display: flex;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-grow-2 {
    flex-grow: 2;
}

.cursor-disabled {
    cursor: not-allowed !important;
}

.center {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}
.space-around {
    justify-content: space-around;
}

.align-center {
    align-items: center;
}

.search-and-page-controls {
    min-height: 32px;
    padding: 0 0 0 30px;
    .page-controls {
        margin-right: 24px;
        span {
            margin-right: 4px;
        }
        path {
            fill: $UI-900 !important;
        }
    }
    .button-primary {
        margin-right: 8px;
    }
}

@mixin truncated() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
}

@mixin truncated-inline-block() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
    display: inline-block !important;
}

@mixin custom-list-item() {
    display: flex;
    font-size: $standardFontSize;
    min-height: 40px;
    height: auto;
    // flex-wrap: wrap;
    border-radius: 6px;
    margin-left: 30px;
    margin-right: 30px;
    flex-wrap: wrap;
    height: auto;

    &:hover {
        background-color: $neutralColor50;
    }
    &[id='no-hover'] {
        &:hover {
            background: $UI-50;
        }
    }
    // &:first-child {
    // margin-top: 25px;
    // }
}

#timeline-view {
    width: 80%;
    display: inline-block;
}

.truncated {
    @include truncated();
}

.truncated-inline-block {
    @include truncated-inline-block();
}

.custom-list-header {
    display: flex;
    font-weight: bold;
    height: auto;
    font-size: $standardFontSize;
    background-color: $UI-50;
    border-bottom: 1px solid $neutralColor100;
    margin-top: 16px;
    margin-left: 30px;
    margin-right: 30px;
    // border-radius: 6px;
}
.custom-list-item {
    @include custom-list-item();
}

.hover-disabled {
    &:hover {
        background-color: inherit;
    }
}

.hover-light {
    padding: 2px;
    border-radius: 6px;
    &:hover {
        color: $UI-900;
        background-color: $neutralColor50;
        svg path {
            fill: $UI-900;
        }
    }
}

.custom-list-item-posDisplayConfig {
    .custom-list-item > div {
        display: block;
        margin-top: 15px;
        margin-bottom: 15px;
        word-wrap: break-word;

        &:last-child {
            display: flex;
        }
    }
}

.custom-list-item > div,
.custom-list-header > div {
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-right: 8px;
    // word-break: break-word;
    &:first-child {
        text-align: left;
        justify-content: left;
        // padding-left: 20px;
    }
    &:last-child {
        text-align: right;
        justify-content: right;
        padding-right: 8px;
    }
}

.custom-list-item.word-break > div {
    word-break: break-word;
}

.custom-list-item-last-child-text > div {
    &:last-child {
        text-align: left !important;
        justify-content: left !important;
        padding-right: 8px;
    }
}

.custom-list-item-table {
    width: max-content;
    .custom-list-item > div {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: left;
        flex: 1 1 auto;
    }
}

.custom-list-header-table {
    width: max-content;
    .custom-list-header > div {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: left;
        flex: 1 1 auto;
    }
}

.custom-list-item-text-right {
    &:last-child {
        text-align: right !important;
        justify-content: right !important;
        padding-right: 8px;
    }
}

.list-item-text-right {
    text-align: right !important;
    justify-content: right !important;
    padding-right: 8px;
}

.custom-list-wrapper {
    .custom-list-header > div,
    .custom-list-item > div {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: left;
        // flex: 1 1 auto;
    }
}

.list-item-big-content {
    margin-left: 30px;
    margin-right: 30px;
    &:hover {
        background-color: $neutralColor50;
    }
}

.report-link {
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    height: 30px;
    .button-link {
        padding-right: 20px;
        font-size: 14px;
    }
}

.list-item-big-content > div {
    margin-left: 0;
    margin-right: 0;
}

.fill-red {
    svg path {
        fill: $errorColor500;
    }
}

.fill-white {
    svg path {
        fill: $UI-50;
    }
}

.fill-primary {
    svg path {
        fill: $primaryColor500;
        stroke: $primaryColor500;
    }
}

.color-success {
    color: $sucessColor500;
    svg path {
        fill: $sucessColor500;
    }
}
.color-success-lighter {
    color: #06bfb4;
    svg path {
        fill: #06bfb4;
    }
}
.color-error {
    color: $errorColor500;
    svg path {
        fill: $errorColor500;
    }
}
.color-error-lighter {
    color: #ec1010;
    svg path {
        fill: #ec1010;
    }
}
.color-warning {
    color: $warningColor500;
    svg path {
        fill: $warningColor500;
    }
}
.color-info {
    color: $primaryColor100;
    svg path {
        fill: $primaryColor100;
    }
}
.color-primary {
    color: $primaryColor !important;
    svg path {
        fill: $primaryColor;
    }
}
.color-primary-dashboard-icon {
    color: $primaryColor !important;
    svg path {
        stroke: $primaryColor;
    }
}
.color-secondary {
    color: $neutralColor500;
    svg path {
        fill: $neutralColor500;
    }
}
.color-neutral {
    @include regular10Font();
    line-height: 20px;
    color: $neutralColor700;
    svg path {
        fill: $neutralColor700;
    }
}
.color-white {
    color: $UI-50;
    svg path {
        fill: $UI-50;
    }
}
.bg-color-error {
    background-color: $errorColor500;
}
.bg-color-error-lighter {
    background-color: #fcdbdb;
}
.bg-color-secondary {
    background-color: $neutralColor500;
}
.bg-color-primary {
    background-color: $primaryColor500 !important;
}
.bg-color-primary-lighter {
    background-color: $primaryColor50 !important;
}
.bg-color-warning {
    background-color: $warningColor500;
}
.bg-color-info {
    background-color: $primaryColor100;
}
.bg-color-light {
    background-color: $neutralColor100;
}
.bg-color-success {
    background-color: $sucessColor500;
}
.bg-color-success-lighter {
    background-color: #dbf8f6;
}
.bg-color-dark {
    background-color: $UI-900;
}
.uppercase {
    text-transform: uppercase;
}
.bold-letters {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
}

.title-with-options {
    min-height: $minHeightTitle;
}

.flex-center-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin margin($marginDirection, $marginValue) {
    #{$marginDirection}: $marginValue;
}

.m-20p {
    margin: 20px;
}

.mt-4p {
    @include margin(margin-top, 4px);
}

.mt-6p {
    @include margin(margin-top, 6px);
}

.mt-8p {
    @include margin(margin-top, 8px);
}

.mt-12p {
    @include margin(margin-top, 12px);
}

.mt-14p {
    @include margin(margin-top, 14px);
}

.mt-16p {
    @include margin(margin-top, 16px);
}

.mt-26p {
    @include margin(margin-top, 26px);
}


.mt-20p {
    @include margin(margin-top, 20px);
}

.mt-28p {
    @include margin(margin-top, 28px);
}

.mt-32p {
    @include margin(margin-top, 32px);
}

.mt-64p {
    @include margin(margin-top, 64px);
}

.mt-70p {
    @include margin(margin-top, 70px);
}

.mt-100p {
    @include margin(margin-top, 100px);
}

.mb-2p {
    @include margin(margin-bottom, 2px);
}

.mb-4p {
    @include margin(margin-bottom, 4px);
}

.mb-6p {
    @include margin(margin-bottom, 6px);
}

.mb-8p {
    @include margin(margin-bottom, 8px);
}

.mb-10p {
    @include margin(margin-bottom, 10px);
}

.mb-16p {
    @include margin(margin-bottom, 16px);
}

.mb-20p {
    @include margin(margin-bottom, 20px);
}

.mb-32p {
    @include margin(margin-bottom, 32px);
}

.ml-2p {
    @include margin(margin-left, 2px);
}

.ml-4p {
    @include margin(margin-left, 4px);
}

.ml-8p {
    @include margin(margin-left, 8px);
}

.ml-12p {
    @include margin(margin-left, 12px);
}

.ml-16p {
    @include margin(margin-left, 16px);
}

.ml-20p {
    @include margin(margin-left, 20px);
}

.ml-24p {
    @include margin(margin-left, 24px);
}

.ml-32p {
    @include margin(margin-left, 32px);
}

.ml-40p {
    @include margin(margin-left, 40px);
}

.mr-2p {
    @include margin(margin-right, 2px);
}
.ml-24p {
    @include margin(margin-left, 24px);
}

.mr-4p {
    @include margin(margin-right, 4px);
}

.mr-8p {
    @include margin(margin-right, 8px);
}

.mr-10p {
    @include margin(margin-right, 10px);
}

.mr-12p {
    @include margin(margin-right, 12px);
}

.mr-14p {
    @include margin(margin-right, 14px);
}

.mr-16p {
    @include margin(margin-right, 16px);
}
.mr-20p {
    @include margin(margin-right, 20px);
}
.mr-24p {
    @include margin(margin-right, 24px);
}
.mr-30p {
    @include margin(margin-right, 30px);
}
.mr-40p {
    @include margin(margin-right, 40px);
}
.ml-30p {
    @include margin(margin-left, 30px);
}
.mt-30p {
    @include margin(margin-top, 30px);
}
.mb-30p {
    @include margin(margin-bottom, 30px);
}

.rc-time-picker-input-custom {
    input {
        background: #ffffff;
        border: 1px solid #d8e0ec;
        border-radius: 6px;
        height: 32px;
        width: 80px;
        padding: 8px 16px;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
}
.date-picker-input-custom {
    background: #ffffff;
    border: 1px solid #d8e0ec;
    border-radius: 6px;
    height: 32px;
    width: 120px;
    padding: 8px 16px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.white-list-access-form {
    .custom-list-item {
        margin-left: 0;
        margin-right: 0;
    }
}

.custom-list-item-modal {
    .custom-list-item {
        // overflow: hidden;
        margin-left: 0;
        margin-right: 0;
        &:hover {
            background-color: transparent;
        }
    }
}

.custom-list-header-modal {
    .custom-list-header {
        margin-top: 0px;
        margin-left: 0;
        margin-right: 0;
    }
}

.custom-list-header-modal-invoice {
    .custom-list-header {
        margin-top: 0px;
        margin-left: 0;
        margin-right: 0;
    }
}

@mixin border($borderDirection) {
    #{$borderDirection}: 1px solid $neutralColor100;
}

.border-bottom {
    @include border(border-bottom);
}

.border-top {
    @include border(border-top);
}
.border-left {
    @include border(border-left);
}

.border-right {
    @include border(border-right);
}

@mixin badge($backgroundColor) {
    background-color: $backgroundColor;
}

@mixin badge($backgroundColor) {
    display: inline-block;
    padding: 4px 8px;
    font-size: 12px;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 6px;
    background-color: $backgroundColor;
    margin: 2px;
}

.badge-primary {
    @include badge($primaryColor500);
}

//context-menu

.context-menu {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.context-menu-options {
    display: none;
    position: absolute;
    top: 100%;
    // right: 4px;
    text-align: left;
    z-index: 999;
    margin: 0;
    padding: 4px;
    list-style: none;
    background-color: $UI-50;
    box-shadow: 0px 1px 2px rgba(115, 115, 115, 0.75);
    margin-block: 4px;
    width: 200px;

    li {
        margin: 0;
        padding: 2px 8px 2px 8px;
        // position: sticky;
        &:hover,
        &.is-selected {
            color: inherit;
            background: $neutralColor50;
        }
        &[id='hide'] {
            display: none;
        }
    }
}

.table-context-menu-options {
    display: inline-block;
    position: absolute;
    top: 100%;
    text-align: left;
    z-index: 999;
    margin: 4px 4px 4px 0;
    padding: 4px;
    list-style: none;
    background-color: $UI-50;
    box-shadow: 0px 1px 2px rgba(115, 115, 115, 0.75);
    max-height: 450px;
    overflow-y: auto;
    min-width: 150px;
    // width: 150px;
    // margin-block: 4px;

    div {
        // margin: 0;
        //  padding: 0;
        // position: sticky;
        cursor: pointer;
        &:hover,
        &.is-selected {
            color: inherit;
            background: $neutralColor50;
        }
        &[id='hide'] {
            display: none;
        }
        &[id='no-hover'] {
            &:hover {
                background: $UI-50;
            }
        }
        &[id='input-container'] {
            &:hover {
                background: $UI-50;
            }
        }
    }
}

.table-context-menu-selector {
    border: 1px solid $neutralColor100;
    margin: 4px;
    border-radius: 6px;
}

.table-input-container {
    margin: 4px;
    .input-container {
        width: inherit;
    }
}
.button-link {
    width: 115px;
    height: 16px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $primaryColor500;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-top: 32px;
    cursor: pointer;
    text-transform: uppercase;
}

.mulitple-select-wrapper {
    display: inline-flex;
    margin-left: 20px;
    div {
        margin-right: 5px;
    }
}
.float-right {
    float: right;
}
.occupancy-icon {
    position: absolute;
    right: 0;
    margin-right: 12px;
}

.text-link {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $primaryColor500;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
    padding: 10px;
    display: inline-flex;
}

.text-link-disabled {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $neutralColor500;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
    padding: 10px;
    display: inline-flex;
}

@mixin padding($paddingDirection, $paddingValue) {
    #{$paddingDirection}: $paddingValue;
}

.p-20p {
    padding: 20px;
}

.p-6p {
    padding: 6px;
}

.p-10p {
    padding: 10px;
}

.pt-2p {
    @include padding(padding-top, 2px);
}

.pt-4p {
    @include padding(padding-top, 4px);
}

.pt-6p {
    @include padding(padding-top, 6px);
}

.pt-8p {
    @include padding(padding-top, 8px);
}

.pt-16p {
    @include padding(padding-top, 16px);
}

.pb-4p {
    @include padding(padding-bottom, 4px);
}

.pb-8p {
    @include padding(padding-bottom, 8px);
}

.pb-10p {
    @include padding(padding-bottom, 10px);
}

.pb-16p {
    @include padding(padding-bottom, 16px);
}

.pl-0p {
    @include padding(padding-left, 0px);
}

.pl-2p {
    @include padding(padding-left, 2px);
}

.pl-4p {
    @include padding(padding-left, 4px);
}

.pl-8p {
    @include padding(padding-left, 8px);
}

.pl-16p {
    @include padding(padding-left, 16px);
}

.pl-20p {
    @include padding(padding-left, 20px);
}

.pr-0p {
    @include padding(padding-right, 0px);
}

.pr-2p {
    @include padding(padding-right, 2px);
}

.pr-4p {
    @include padding(padding-right, 4px);
}

.pr-8p {
    @include padding(padding-right, 8px);
}

.pr-10p {
    @include padding(padding-right, 10px);
}

.pr-16p {
    @include padding(padding-right, 16px);
}
.pr-30p {
    @include padding(padding-right, 30px);
}
.pl-30p {
    @include padding(padding-left, 30px);
}

.tab {
    color: $UI-900;
    background-color: $neutralColor50;
    text-align: center;
    padding: 4px;
    cursor: pointer;
    svg path {
        fill: $UI-900;
    }
    &:hover {
        color: $UI-900;
        background-color: $neutralColor100;
        svg path {
            fill: $UI-900;
        }
    }
}
.tab.active {
    background-color: $primaryColor50;
    color: $primaryColor500;
}

.primary-bg {
    background-color: $primaryColor500 !important;
}
.primary-bg-3 {
    background-color: $primaryColor300;
}
.primary-bg-7 {
    background-color: $primaryColor700;
}
.primary-bg-1 {
    background-color: $primaryColor100;
}
.border-radius-4 {
    border-radius: 6px;
}
.neutral-bg-50 {
    background-color: $neutralColor50;
}
.white-bg {
    background-color: #fff;
}
.font-16 {
    font-size: 16px !important;
}
.flex-column {
    flex-direction: column;
}

.h-100 {
    height: 100%;
}

// div.custom-input-field {
// 	display: flex;
// 	align-items: flex-start;
// 	justify-content: space-around;
// 	margin-top: 30px;
// 	border: 1px solid whitesmoke;
// 	padding: 21px;
// 	border-radius: 6px;
// }
h4.title {
    text-align: center;
    margin-bottom: 45px;
}
:root {
    --omrs-color-ink-lowest-contrast: rgba(47, 60, 85, 0.18);
    --omrs-color-ink-low-contrast: rgba(60, 60, 67, 0.3);
    --omrs-color-ink-medium-contrast: rgba(19, 19, 21, 0.6);
    --omrs-color-interaction: #1e4bd1;
    --omrs-color-interaction-minus-two: rgba(73, 133, 224, 0.12);
    --omrs-color-danger: #b50706;
    --omrs-color-bg-low-contrast: #eff1f2;
    --omrs-color-ink-high-contrast: #121212;
    --omrs-color-bg-high-contrast: #ffffff;
}
/** END: Non Openmrs CSS **/
div.omrs-input-group {
    position: relative;
}

/* Input*/
.omrs-input-underlined > input,
.omrs-input-filled > input {
    border: none;
    //	border-bottom: 0.125rem solid var(--omrs-color-ink-medium-contrast);
    width: 100%;
    height: 36px;
    font-size: 12px;
    padding-left: 4px;
    //line-height: 147.6%;
    padding-top: 4px;
    padding-bottom: 4px;
}

.omrs-input-underlined > input:focus,
.omrs-input-filled > input:focus {
    outline: none;
}

.omrs-input-underlined > .omrs-input-label,
.omrs-input-filled > .omrs-input-label {
    position: absolute;
    top: 0.9375rem;
    left: 0.875rem;
    line-height: 147.6%;
    color: var(--omrs-color-ink-medium-contrast);
    transition: top 0.2s;
}

.omrs-input-underlined > svg,
.omrs-input-filled > svg {
    position: absolute;
    top: 0.9375rem;
    right: 0.875rem;
    fill: var(--omrs-color-ink-medium-contrast);
}

.omrs-input-underlined > .omrs-input-helper,
.omrs-input-filled > .omrs-input-helper {
    font-size: 0.9375rem;
    color: var(--omrs-color-ink-medium-contrast);
    letter-spacing: 0.0275rem;
    margin: 0.125rem 0.875rem;
}

.omrs-input-underlined > input:hover,
.omrs-input-filled > input:hover {
    background: var(--omrs-color-interaction-minus-two);
    border-color: var(--omrs-color-ink-high-contrast);
}

.omrs-input-underlined > input:focus + .omrs-input-label,
.omrs-input-underlined > input:valid + .omrs-input-label,
.omrs-input-filled > input:focus + .omrs-input-label,
.omrs-input-filled > input:valid + .omrs-input-label {
    top: 0;
    font-size: 0.9375rem;
    margin-bottom: 32px;
}

.omrs-input-underlined:not(.omrs-input-danger) > input:focus + .omrs-input-label,
.omrs-input-filled:not(.omrs-input-danger) > input:focus + .omrs-input-label {
    color: var(--omrs-color-interaction);
}

.omrs-input-underlined:not(.omrs-input-danger) > input:focus,
.omrs-input-filled:not(.omrs-input-danger) > input:focus {
    border-color: var(--omrs-color-interaction);
}

.omrs-input-underlined:not(.omrs-input-danger) > input:focus ~ svg,
.omrs-input-filled:not(.omrs-input-danger) > input:focus ~ svg {
    fill: var(--omrs-color-ink-high-contrast);
}

/** DISABLED **/

.omrs-input-underlined > input:disabled {
    background: var(--omrs-color-bg-low-contrast);
    cursor: not-allowed;
}

.omrs-input-underlined > input:disabled + .omrs-input-label,
.omrs-input-underlined > input:disabled ~ .omrs-input-helper {
    color: var(--omrs-color-ink-low-contrast);
}

.omrs-input-underlined > input:disabled ~ svg {
    fill: var(--omrs-color-ink-low-contrast);
}

/** DANGER **/

.omrs-input-underlined.omrs-input-danger > .omrs-input-label,
.omrs-input-underlined.omrs-input-danger > .omrs-input-helper,
.omrs-input-filled.omrs-input-danger > .omrs-input-label,
.omrs-input-filled.omrs-input-danger > .omrs-input-helper {
    color: var(--omrs-color-danger);
}

.omrs-input-danger > svg {
    fill: var(--omrs-color-danger);
}

.omrs-input-danger > input {
    border-color: var(--omrs-color-danger);
}

.omrs-input-underlined > input {
    background: var(--omrs-color-bg-high-contrast);
}
.omrs-input-filled > input {
    background: var(--omrs-color-bg-low-contrast);
}

.text-bold {
    font-weight: 700;
}
.list-hover {
    &:hover {
        background-color: $primaryColor50;
    }
}

.tabs-list-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 492px;
    height: 32px;
    left: 20px;
    top: 20px;
}

.tabs-list {
    display: flex;
    padding: 6px 13px;
    cursor: pointer;
    &:hover {
        color: $UI-900;
        background-color: $neutralColor100;
        svg path {
            fill: $UI-900;
        }
    }
    a {
        color: $UI-900;
    }
}

.tab-active {
    color: $primaryColor;
    border-bottom: 2px solid $primaryColor;
}

.role-sidebar {
    width: 16%;
    height: calc(100% - $minHeightTitle);
    overflow-y: scroll;
    overflow-x: hidden;
    min-width: 150px;
    div {
        margin: 2px;
        font-size: 14px;
        // font-weight: bold;
    }
}

.role-sidebar-right {
    width: 84%;
    height: calc(100% - $minHeightTitle);
    min-width: 600px;
    overflow-y: auto;
}

.item-label-font {
    font-size: 14px !important;
}

.text-bold {
    font-weight: bold;
}

.flex {
    flex: 1;
}

textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow: auto;
    resize: vertical;
}

textarea.input.input-modal {
    height: auto;
}

textarea.input.input-modal:valid,
textarea.input.input-modal.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.input.input-modal.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.75em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
select.input.input-modal.is-invalid {
    background-position: center right calc(0.75em + 0.1875rem);
}

.input.input-modal.is-invalid-warning {
    border-color: #fcdf90;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.75em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
select.input.input-modal.is-invalid-warning {
    background-position: center right calc(0.75em + 0.1875rem);
}

.right-border {
    border-right: 1px solid $errorColor500;
    padding: 0 15px 0 15px;
}
.flex-1 {
    flex: 1;
}
.hardware-rooms > div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
}

.reservation-form {
    // height: calc(100% - 400px);
    min-height: 600px;
    width: 600px;
    max-width: 100%;
    position: absolute;
    z-index: 9999;
    background-color: white;
    border: 1px solid red;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 12px 0px gray !important;
}

.reservation-form-foother {
    display: inline;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    justify-content: flex-end;
    bottom: 0;
    margin-top: auto;
    background-color: $UI-50;
}

.reservation-form-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px !important;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #00112b;
}
.reservation-form-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.reservation-form-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.reservation-form-main-wrapper {
    // flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;
    // position: relative;
    // top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    // height: auto;
    flex-wrap: wrap;
    margin-top: 5%;
}

.reservation-tabs-wrapper {
    display: flex;
    gap: 8px;
    left: 0;
    top: 0;
    flex-wrap: wrap;
    width: 100%;
    max-height: 100px;
    overflow-y: auto;
}

.reservation-tabs-single {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: none;
    flex-grow: 0;
    cursor: pointer;

    div.active {
        border-bottom: 1px solid $primaryColor;
        color: $primaryColor;
        svg path {
            fill: $primaryColor;
        }
    }
    div.valid-res-form {
        color: $UI-900;
        svg path {
            fill: $UI-900;
        }
    }
    div.invalid-res-form {
        color: $errorColor500;
        svg path {
            fill: $errorColor500;
        }
    }
}

.reservation-body-wrapper {
    box-sizing: border-box;
    left: 0;
    top: 0;
    border: 1px solid $neutralColor100;
    border-radius: 6px;
    width: 100%;
    height: 80%;
    padding: 10px;
    background-color: $UI-50;
}

.reservation-room {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.reservation-form-tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    flex-wrap: wrap;
}

.reservation-form-tabs-single {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    height: 32px;
    color: $neutralColor300;
    border-bottom: 1px solid $neutralColor100;
    cursor: pointer;
    margin-bottom: 4px;
}

.active-tab {
    border-bottom: 1px solid $neutralColor500;
    color: $neutralColor500;
}

.pos-item-one {
    width: 210px;
    height: 140px;
    background: $UI-50;
    border: 1px solid $neutralColor100;
    // box-shadow: 0px 8px 20px rgba(105, 119, 140, 0.15);
    border-radius: 6px;
    // margin: 10px;
    position: relative;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
}

.pos-item-image {
    margin-left: 10px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    img {
        border: 1px solid $neutralColor100;
        border-radius: 10px;
        width: 50px;
        height: 50px;
    }
}

.pos-item-name {
    align-items: flex-start;
    flex-wrap: wrap;
}

.pos-item-calc {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 24px;
    right: 10px;
    bottom: 10px;
}

.flexible-price-div {
    position: absolute;
    background-color: $UI-50;
    z-index: 5555;
    top: 125px;
    left: 10px;
    padding: 10px;
}

.pos-sidebar {
    padding: 8px 4px 8px 4px;
    display: flex;
    height: 90%;
}

.pos-invoice-summary {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    color: $primaryColor;
    font-weight: bold;
}

.customers-and-company-search-result {
    border: 1px solid $neutralColor100;
    min-height: auto;
    max-height: 200px;
    position: absolute;
    min-width: 300px;
    // width: 90%;
    // max-width: 450px;
    // width: auto;
    background-color: white;
    z-index: 30;
    overflow-y: scroll;
}

.more-options-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    position: absolute;
    width: 223px;
    right: 10px;
    background: $UI-50;
    border: 1px solid $neutralColor50;
    border-radius: 6px;
    z-index: 8888;
}

.more-options {
    display: flex;
    align-items: center;
    width: 100%;
    &:hover {
        @include menu-item-hovered();
    }
    svg {
        &:hover {
            @include menu-item-hovered();
            border: none;
            padding-right: 0;
        }
    }
}

.more-options-checkout-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
    position: absolute;
    width: 223px;
    right: 0px;
    background: $UI-50;
    border: 1px solid $neutralColor50;
    border-radius: 6px;
    z-index: 30;
    margin-top: 4px;
}

.more-options-checkout {
    display: flex;
    align-items: center;
    width: 100%;
    &:hover {
        @include menu-item-hovered();
    }
    svg {
        &:hover {
            @include menu-item-hovered();
            border: none;
            padding-right: 0;
        }
    }
}

.reservation-form-accommodation {
    padding: 5px 10px 5px 10px;
    background-color: $primaryColor50;
    border: 1px solid $primaryColor100;
    display: flex;
    align-items: center;
    left: 20px;
    bottom: 20px;
    font-weight: bold;
    border-radius: 6px;
}

.reservation-details-row {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-radius: 6px;
    font-size: 14px;
    margin-bottom: 2px;
}

@media all and (min-width: 0px) and (max-width: 450px) {
    /* put your css styles in here */
    .mobile-page-form {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}

.input-modal-group-text {
    padding: 8px;
    border: 1px solid $neutralColor500;
    border-radius: 6px;
    background-color: $neutralColor100;
}

.invoice-presenter {
    border: 1px solid;
    border-color: $neutralColor100;
    border-radius: 6px;
    margin: 8px;
    overflow-y: auto;
    // height: calc(100% - 178px);
    z-index: 2;
    height: 75vh;
    font-size: 14px;
}

.button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px 4px 0px;
    height: 32px;
}

.color-dark {
    color: $UI-900;
    svg path {
        fill: $UI-900;
    }
}
.input-field-invalid {
    border-color: #dc3545;
}

.checkout-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 8px 8px 24px 8px;
}

.invoice-item {
    @include custom-list-item();
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 8px;
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    font-size: 14px;
}

.invoice-item-selected {
    @include custom-list-item();
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 8px;
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    background-color: $neutralColor50;
    font-size: 14px;
}

.invoice-item.disabled {
    background-color: #ededed;
}
.button-custom-list-item {
    @include custom-list-item();
    min-width: 300px;
    width: 100%;
    background-color: $neutralColor50 !important;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    min-height: 32px;
    // margin-top: 1px !important;
    &:focus {
        border: 1px solid $neutralColor700 !important;
        // outline: 1px solid $neutralColor700 !important;
        outline: none !important;
    }
    height: auto;
}
.list-style-none {
    list-style-type: none;
    list-style: none;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.flex-auto {
    flex: auto;
}

.custom-list-item-report {
    border-bottom: 1px solid;
    border-color: $neutralColor100;
    border-radius: 6px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
}

@mixin category-menu-item-list-mixin {
    @include flex-center();
    justify-content: left;
    padding: 8px;
    cursor: pointer;
    margin: 0 16px 0 0;
    flex-direction: column;
    border-radius: 6px;

    &:hover {
        background-color: $neutralColor100;
    }
}

.category-menu-item-list {
    @include category-menu-item-list-mixin();
}

.category-menu-item-list-selected {
    @include category-menu-item-list-mixin();
    color: $primaryColor500;
    background-color: $primaryColor50;
    @include bold-letters();
}

.button-link-primary {
    height: 16px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $primaryColor500;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-top: 32px;
    cursor: pointer;
}

.invoice-item-presenter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d8e0ec;
    box-shadow: 0px 2px 5px rgba(105, 119, 140, 0.15);
    border-radius: 6px;
    min-height: 44px;
    height: max-content;
}

.invoice-item-presenter {
    align-items: center;
}

.persistent-notification-system-container {
    padding: 10px;
}

.persistent-notification-system-group {
    margin-bottom: 10px;
}

.persistent-notification-system-group-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.persistent-notification-system {
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    color: white;
}

.persistent-notification-system-message {
    padding: 5px;
}

.persistent-notification-system-warning-button {
    svg path {
        fill: $errorColor500;
    }
    svg {
        width: 52px;
        height: 52px;
    }
    position: absolute;
    top: 0;
    right: 0;
}

.guests-book {
    display: flex;
    align-items: center;
    height: 70px;
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    background-color: $neutralColor100;
}

.user-rooms {
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    color: #000;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid black;
}

.primary-svg {
    svg path {
        fill: $primaryColor;
    }
    display: flex;
    align-items: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.chm-card-wrapper {
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: auto;
    min-height: 300px;
    max-height: 300px;
    border: 1px solid $neutralColor100;
    border-radius: 10px;
    justify-content: space-between;
}

@mixin chm-card {
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.chm-card-title-and-rooms {
    @include chm-card();
    padding: 6px;
}

.chm-card-plans {
    @include chm-card();
    height: 100%;
    overflow-y: auto;
    padding: 10px;
}

.chm-rooms-wrapper {
    display: flex;
    width: 100%;
    overflow-x: auto;
    margin-top: 5px;
}

.chm-single-room {
    background-color: $primaryColor50;
    color: $primaryColor500;
    margin-right: 5px;
    padding: 2px 7px 2px 7px;
    border-radius: 6px;
}

.chm-table-rooms-wrapper {
    display: flex;
    width: 100%;
    overflow-x: auto;
    margin-top: 5px;
    justify-content: center;
    font-size: 12px;
}

.chm-single-plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $neutralColor100;
    padding: 5px;
}
.chm-mapping-plans {
    display: flex;
    margin-top: 15px;
    margin-left: 5px;
    align-items: center;
    border-radius: 6px;
    justify-content: space-between;
    line-height: 16px;
}
.chm-mapping-select-pricelist {
    display: flex;
    justify-content: flex-end;
}

.tab-no-selected {
    color: $UI-900;
    font-size: 14px;
    border-bottom: 1px solid $neutralColor100;
}

.tab-selected {
    color: $primaryColor500;
    font-size: 14px;
    border-bottom: 1px solid $primaryColor500;
}

.chm-plan-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.konjina {
    display: flex;
    flex-direction: column;
}

.chm-plan-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    border: 1px solid $neutralColor100;
    border-radius: 10px;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;
}
.chm-plan-sync-and-dots {
    @include chm-card();
    padding: 10px;
}

.flex-plan-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 10px;
}

.button-plan-sync-link {
    font-family: 'Work Sans';
    font-style: normal;
    font-size: 16px;
    color: $primaryColor500;
    cursor: pointer;
}

.plans-style {
    border-bottom: 2px solid $neutralColor100;
    padding-bottom: 25px;
}

.virtual-plan-wrapper {
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
    margin-top: 15px;
    cursor: pointer;
}

.svg-plus-icon {
    height: 35px;
}
.svg-plus-icon-virtualPlan {
    height: 25px;
}

.restrictions-form-tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
}

.restrictions-form-tabs-single {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    height: 32px;
    cursor: pointer;
    font-size: 12px;
}

.selected-days-in-week {
    padding: 8px;
    color: $primaryColor500;
    background-color: $primaryColor50;
    margin-right: 8px;
    border-radius: 6px;
    cursor: pointer;
}

.no-selected-days-in-week {
    padding: 8px;
    color: $UI-900;
    background-color: $neutralColor50;
    margin-right: 8px;
    border-radius: 6px;
    cursor: pointer;
}

.weekly-on-days {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.title-with-options-page-manageRestrictions {
    min-height: $minHeightTitle;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 400px) {
    .title-with-options-page-manageRestrictions {
        justify-content: flex-start;
        align-items: center;
    }
}

.flex-space-between-search-dates-updateButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1010px) {
    .flex-space-between-search-dates-updateButton {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 8px;
    }
}

.flex-center-search-dates {
    display: flex;
    align-items: flex-start;
}

@media (max-width: 1010px) {
    .flex-center-search-dates {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
    }
}

.select-dates {
    margin-top: 0;
}

@media (max-width: 1010px) {
    .select-dates {
        margin-top: 10px;
        margin-left: 0;
    }
}

.button-link-update-avail {
    width: 115px;
    height: 16px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $primaryColor500;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-top: 0;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 8px;
}

@media (max-width: 1010px) {
    .button-link-update-avail {
        margin-left: 0;
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 14px;
    }
}

.manage-restrictions-select-dates {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .manage-restrictions-select-dates {
        flex-direction: column;
        justify-content: flex-start;
    }
}

@media (max-width: 768px) {
    .display-none-line {
        display: none;
    }
}

@media (max-width: 768px) {
    .manage-to-date {
        margin-top: 10px;
    }
}

@media (max-width: 768px) {
    .selected-days-in-week {
        padding: 5px;
        color: $primaryColor500;
        background-color: $primaryColor50;
        margin-right: 8px;
        border-radius: 6px;
        cursor: pointer;
    }
}

@media (max-width: 359px) {
    .selected-days-in-week {
        padding: 2.5px;
        color: $primaryColor500;
        background-color: $primaryColor50;
        margin-right: 8px;
        border-radius: 6px;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .no-selected-days-in-week {
        padding: 5px;
        color: $UI-900;
        background-color: $neutralColor50;
        margin-right: 8px;
        border-radius: 6px;
        cursor: pointer;
    }
}

@media (max-width: 359px) {
    .no-selected-days-in-week {
        padding: 2.5px;
        color: $UI-900;
        background-color: $neutralColor50;
        margin-right: 8px;
        border-radius: 6px;
        cursor: pointer;
    }
}

@media (max-width: 359px) {
    .restrictions-form-tabs-single {
        font-size: 10px;
        font-weight: bold;
    }
}
.restrictionTableView {
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.restrictionTableParts {
    margin-right: 5px;
}

.load-button {
    padding: 7px;
    background-color: $primaryColor500;
    color: white;
    margin-left: 10px;
    border-radius: 6px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}

.restrictionTable-container {
    overflow-x: auto;
}

.restrictionTable-container::-webkit-scrollbar {
    height: 6px;
    cursor: pointer;
}

.restrictionTable-container::-webkit-scrollbar-thumb {
    box-shadow: none;
}

.restrictionTable {
    border-collapse: collapse;
}

.restrictionTable th,
.restrictionTable td {
    border: 1px solid #000;
    padding: 15px;
    text-align: center;
    position: relative;
    min-width: 170px;
    width: auto;
}
th:first-child,
td:first-child {
    position: sticky;
    left: 0;
    background-color: #fff; /* Dodajte boju pozadine po želji */
    z-index: 1;
}

.restrictionTable th {
    background-color: #f2f2f2;
}

.priceRes,
.openCloseRes,
.min-stayRes,
.availRes,
.availColorsRes {
    position: absolute;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.priceRes {
    top: 0;
    left: 0;
}

.openCloseRes {
    top: 0;
    right: 0;
}

.openCircleRes {
    width: 13px;
    height: 13px;
    background-color: #000000; /* Boja kruga */
    border-radius: 50%; /* Postavljanje radijusa na 50% za stvaranje kruga */
}

.closedCircleRes {
    width: 13px;
    height: 13px;
    border: 2px solid #8f989f;
    border-radius: 50%; /* Postavljanje radijusa na 50% za stvaranje kruga */
}

.min-stayRes {
    bottom: 0;
    left: 0;
}

.availRes {
    bottom: 0;
    right: 0;
}

.availColorsRes {
    bottom: 0;
    right: 0;
    background-color: #dd4c4c;
    color: white;
    padding: 0 5px 0 5px;
}
.custom-year-month-select-dayPicker {
    select.input {
        width: auto !important;

        option {
            padding-top: 4px;
            font-size: 16px !important;
            line-height: 14px;
        }
    }

    ::-webkit-scrollbar {
        height: 8px;
        width: 10px;
        background: $neutralColor50;
    }

    ::-webkit-scrollbar-thumb {
        background: $neutralColor700;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(115, 115, 115, 0.75);
    }

    ::-webkit-scrollbar-corner {
        background: rgb(81, 81, 81);
    }
}

.selected-accounts {
    max-width: 80%;
    margin-right: 8px;
    min-height: 150px;
    max-height: 150px;
    overflow-y: auto;
}

.gl-header {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    height: auto;
    min-width: 100%;
    width: max-content;
    @include regular14Font();
    background-color: #fff;
}

.gl-header > div {
    display: flex;
    align-items: center;
    border: 1px solid $neutralColor300;
    padding-left: 1px;
}

.gl-report-table {
    // display: flex;
    // height: 100%;
    // overflow-y: scroll;
    display: flex;
    white-space: nowrap;
}

#gl-report-table-first-div {
    display: flex;
    flex-direction: column;
    // align-items: center;
    min-height: 40px;
    // height: auto;
    min-width: 600px;
    max-width: 600px;
    width: 600px;
    @include regular14Font();
    display: inline-block;
    word-wrap: normal;
    position: absolute;
    height: 100vh;
    z-index: 30;
}

.gl-report-table-second-div {
    height: max-content;
    overflow-y: hidden;
    overflow-x: auto;
}

.gl-years-title,
.gl-months-title,
.gl-header-list-title,
.gl-header-list-account,
.gl-header-list-ref,
.gl-one-year,
.gl-one-month,
.gl-header-item {
    border-right: 1px solid $neutralColor300;
    // margin-right: 15px;
    padding: 10px;
    min-width: 150px;
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gl-header-item {
    min-width: 120px !important;
    max-width: 120px !important;
}

.gl-title {
    width: auto;
    display: flex;
    align-items: center;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    padding: 10px;
    font-weight: normal;
    // border-bottom: 1px solid $neutralColor300;
}

.gl-years,
.gl-months {
    border-bottom: none !important;
}

.gl-one-year {
    min-width: 720px;
    max-width: 720px;
    cursor: pointer;
    &:hover {
        background-color: $neutralColor100;
    }
    padding: 10px;
}

.gl-months-title,
.gl-years-title {
    min-width: 600px !important;
    max-width: 600px !important;
    border: 1px solid $neutralColor300;
    background-color: #fff;
    @include regular14Font();
}

.smaller-width {
    min-width: 240px !important;
    max-width: 240px !important;
}

.gl-one-month {
    min-width: 720px;
    max-width: 720px;
    cursor: pointer;
    &:hover {
        background-color: $neutralColor100;
    }
    padding: 10px;
}

.gl-header-item,
.gl-header-list-title,
.gl-header-list-account,
.gl-header-list-ref {
    min-height: 58px !important;
    // padding: 10px !important;
}

.gl-header-list-title {
    min-width: 350px !important;
    max-width: 350px !important;
    width: 350px;
    // flex-wrap: wrap;
    background-color: $neutralColor50;
    @include regular14Font();
    white-space: normal;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 5px;
    div {
        text-align: left !important;
        padding-left: 15px;
    }
}

.fixed-div {
    position: sticky;
    left: 0;
}

.gl-header-list-account {
    min-width: 150px !important;
    max-width: 150px !important;
    background-color: $neutralColor50;
    width: 150px;
    height: 100%;
    @include regular12Font();
    white-space: normal;
    padding: 5px;
    small {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.gl-header-list-account.left-text {
    text-align: left !important;
    justify-content: flex-start !important;
    padding-left: 10px;
}

.gl-header-list-ref {
    min-width: 100px !important;
    max-width: 100px !important;
    background-color: $neutralColor50;
    width: 100px;
    flex-wrap: wrap;
    height: 100%;
    @include regular14Font();
    padding: 5px;
}

.gl-header-item {
    font-weight: 500;
    padding: 5px;
    height: 100%;
}

.gl-header-list {
    width: max-content;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $neutralColor100 !important;
}

.gl-header-wrapper {
    width: max-content;
    background-color: $neutralColor50;
}

.gl-font {
    font-weight: normal;
    font-size: 12px;
}

#left-div {
    border-right: 1px solid $neutralColor50;
    background-color: $neutralColor50;
}

.scroll-container::-webkit-scrollbar {
    height: 8px;
    width: 5px;
    cursor: pointer;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1111;
}

.sticky-z {
    z-index: 3333;
    background-color: #fff;
}

.gl-wrap-text {
    white-space: normal !important;
    word-wrap: break-word !important;
    text-align: center !important;
}

.right-border-black {
    border-right: 2px solid $neutralColor700;
}

.breadcrumb-wrapper {
    color: $neutralColor500 !important;
    position: absolute;
    top: 0;
    margin-top: -2px;
    // left:12px;
    width: 500px;
    a {
        color: $neutralColor500 !important;
    }

    svg path {
        fill: $neutralColor500 !important;
    }
    a:hover {
        color: $neutralColor700 !important;
        text-decoration: underline;
    }
}
.reports-tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    margin-left: 17px;
    margin-top: 20px;
    flex-wrap: wrap;
}

.reports-tabs-single {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    min-height: 32px;
    height: max-content;
    color: $neutralColor700;
    border-bottom: 1px solid $primaryColor100;
    cursor: pointer;
    margin-bottom: 10px;
}

.reports-active-tab {
    color: $primaryColor700;
    border-bottom: 1px solid $primaryColor500;
    font-weight: bold;
}

.scrollX {
    overflow-x: scroll;
}

.scrollX::-webkit-scrollbar {
    height: 10px; /* Postavljamo visinu trake za pomicanje (scrollbar) */
    width: 5px;
}

@media print {
    .scrollX {
        overflow-x: hidden;
    }
}

.sm-svg {
    svg {
        width: 25px;
        height: 25px;
    }
}

.flex-center-between-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.align-self-auto-mobile {
    align-self: auto;
}

.flex-center-end-mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}
.flex-center-mobile {
    display: flex;
    align-items: center;
    margin: 0px 8px 0px 8px;
}

.flex-center-mobile-shrink {
    display: flex;
    align-items: center;
    margin: 0px 8px 0px 8px;
    flex-shrink: 1;
    flex-wrap: wrap;
}

.flex-center-mobile-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.select-task-type {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flex-center-between-mobile-wrap {
    display: flex;
    justify-content: space-between;
    margin-left: 16px;
    margin-right: 16px;
}

.household-room-header {
    display: flex;
    justify-content: space-between;
    padding: 4px 8px 4px 8px;
    // background-color: $primaryColor50;
    box-shadow: inset -1px -1px 10px 5px rgba(105, 119, 140, 0.15);
}

.width-25 {
    flex: 0 0 25%;
    // min-width: 294px
}

.width-75 {
    flex: 0 0 75%;
}
.household-manager-wrapper {
    display: flex;
    height: 99%;

    .custom-list-header,
    .custom-list-item {
        min-width: unset;
        margin-left: 4px !important;
        margin-right: 4px !important;
        margin-top: 4px !important;
    }
}

.cleaning-history-wrapper {
    margin-left: 4px;
    .custom-list-header,
    .custom-list-item {
        min-width: unset;
        margin-left: 4px !important;
        margin-right: 4px !important;
        margin-top: 4px !important;
    }
}

.household-views {
    width: 75%;
}

.household-task-templates {
    display: flex;
    justify-content: space-between;
}

.household-room-info {
    position: relative;
}

.household-room-card {
    margin: auto;
    border: 1px solid #dbe8fc;
    position: relative;
    height: 100%;
    width: 600px;
}
.household-main {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;
    .scrollX {
        overflow-x: unset !important;
    }
}
.janitor-wrapper {
    display: flex;
}

.janitor-filters {
    flex: 0 0 25%;
    max-width: 25%;
}
.janitor-main {
    flex: 0 0 75%;
    max-width: 75%;
}
.dragging-room {
    background-color: lightgray;
    opacity: 0.7;
    box-shadow: 4px 4px 10px gray;
}
.household-card-reservation-info {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
}

.no-overflow-x {
    overflow-x: hidden;
}
.closing-div {
    position: absolute;
    bottom: 8px;
    align-items: center;
    text-align: center;
    border-top: #d8e0ec;
    padding-top: 8px;
    width: 100%;
}

.household-card-buttons {
    margin-left: 8px;
    margin-right: 8px;
    width: 42%;
    // margin-top: 12px;
    margin-bottom: 12px;
}

.household-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $neutralColor100;
    margin-bottom: 10px;
    // margin-left: 4px;
    // margin-bottom: 12px;
}

.household-tabs-single {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    color: $UI-900;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
}

.household-active-tab {
    color: $primaryColor500;
    border-bottom: 1px solid $primaryColor500;
}

.overflow-y-mobile {
    overflow-y: hidden;
}

.household-rooms-column {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    max-height: 72vh;
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 16px;
    // justify-content: grid;
}

// .household-rooms-column::after {
//     content: '';
//     // flex: auto;
//     width: 160px;
// }

.household-grouped-rooms-wrapper {
    margin-top: 16px;
    margin-left: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: scroll;
    max-height: 72vh;
}

.household-grouped-rooms-manager {
    margin-right: 32px;
    margin-top: 16px;
    margin-bottom: 16px;
    min-width: 404px;
    flex: 1;
    position: relative;
    box-sizing: border-box; /* Da se padding i border ne računaju dodatno u širinu */
    bottom: 16px;
    justify-content: space-between;
    // border: 1px solid #dbe8fc;
    box-shadow: inset 0px -5px 5px rgba(105, 119, 140, 0.15);
}
.household-grouped-rooms-manager-no-shadow {
    margin-right: 32px;
    margin-top: 2px;
    margin-bottom: 2px;
    min-width: 400px;
    flex: 1;
    position: relative;
    box-sizing: border-box; /* Da se padding i border ne računaju dodatno u širinu */
    bottom: 16px;
    justify-content: space-between;
    // border: 1px solid #dbe8fc;
    // box-shadow: inset 0px -5px 5px rgba(105, 119, 140, 0.15);
}

// .household-grouped-rooms-manager-full-width {
//     margin-right: 8px;
//     margin-top: 16px;
//     width: 400px;
//     // flex: 0 0 calc(33% - 16px);
//     position: relative;
//     box-sizing: border-box; /* Da se padding i border ne računaju dodatno u širinu */
//     bottom: 16px;
//     justify-content: space-between;
//     border: 1px solid  #dbe8fc;

// }

.household-grouped-rooms-maid {
    margin-right: 16px;
    margin-top: 16px;
}

.household-grouped-rooms {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
// .household-grouped-rooms::after {
//     content: '';
//     width: 160px;
// }

.household-room {
    // border: 1px solid #abcaf8;
    border-radius: 5%;
    margin: 4px;
    height: 200px;
    position: relative;
    width: 160px;
    // flex: 0 0 calc(15%);
    // max-width: calc(15% - 4px);
    box-sizing: border-box; /* Da se padding i border ne računaju dodatno u širinu */
    box-shadow: 0px 2px 5px rgba(105, 119, 140, 0.15);
}
.household-room-assigments {
    // border: 1px solid #abcaf8;
    border-radius: 5%;
    margin: 8px 16px 8px 16px;
    height: 180px;
    width: 160px;
    box-shadow: 0px 2px 5px rgba(105, 119, 140, 0.15);
    position: relative;
    // flex: 0 0 calc(30%);
    box-sizing: border-box; /* Da se padding i border ne računaju dodatno u širinu */
}
.household-room-ghost {
    // border: 1px solid #abcaf8;
    border-radius: 5%;
    margin: 8px 16px 8px 16px;
    // height: 180px;
    width: 160px;
    // box-shadow: 0px 2px 5px rgba(105, 119, 140, 0.15);
    position: relative;
    // flex: 0 0 calc(30%);
    box-sizing: border-box; /* Da se padding i border ne računaju dodatno u širinu */
}

.wrap-item {
    margin: 4px;
}

.flex-center-mobile-grid {
    display: flex;
    align-items: center;
}

.color-requested {
    color: $errorColor500;
    svg path {
        fill: $errorColor500;
    }
}

.color-staged {
    color: $warningColor500;
    svg path {
        fill: $warningColor500;
    }
}

.color-started {
    color: $primaryColor500;
    svg path {
        fill: $primaryColor500;
    }
}

.color-finished {
    color: $sucessColor500;
    svg path {
        fill: $sucessColor500;
    }
}

.color-postponed {
    color: $primaryColor100;
    svg path {
        fill: $primaryColor100;
    }
}
.color-skipped {
    color: $warningColor100;
    svg path {
        fill: $warningColor100;
    }
}

.color-common {
    color: $neutralColor700;
    svg path {
        fill: $neutralColor700;
    }
}

.text-arrival {
    color: $primaryColor500;
    text-decoration-line: underline;
    text-decoration-color: $primaryColor500;
    font-weight: 500;
}
.text-due-out {
    color: $warningColor500;
    text-decoration-line: underline;
    text-decoration-color: $warningColor500;
    font-weight: 500;
}

.text-checked-out {
    color: $neutralColor700;
    text-decoration-line: underline;
    text-decoration-color: $neutralColor700;
    font-weight: 500;
}
.text-stay {
    color: $sucessColor500;
    text-decoration-line: underline;
    text-decoration-color: $sucessColor500;
    font-weight: 500;
}
.text-checked-in {
    color: $sucessColor900;
    text-decoration-line: underline;
    text-decoration-color: $sucessColor900;
    font-weight: 500;
}

.household-report-box {
    // border: 1px solid #abcaf8;
    border-radius: 5%;
    margin: 8px;
    height: 160px;
    position: relative;
    width: 320px;
    // flex: 0 0 calc(15%);
    // max-width: calc(15% - 4px);
    box-sizing: border-box; /* Da se padding i border ne računaju dodatno u širinu */
    box-shadow: 0px 2px 5px rgba(105, 119, 140, 0.15);
}

@media (max-width: 820px) {
    .closing-div {
        bottom: 0px;
    }
    .select-task-type {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 4px 0px 4px 0px;
        button {
            width: 100%;
        }
    }
    .align-self-start-mobile {
        align-self: flex-start !important;
    }
    .flex-center-between-mobile {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 4px 0px 4px 0px;
    }
    .household-grouped-rooms-wrapper {
        overflow-y: unset;
        // flex-direction: column;
        max-height: unset;
        // display: grid;
        width: 100%;
        padding-left: 4px;
        padding-right: 4px;
        margin-top: 4px;
        margin-left: 0px;
    }
    .household-grouped-rooms {
        padding-left: 4px;
        padding-right: 4px;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .household-room-assigments {
        margin-right: 2px;
        margin-left: 2px;
        margin-top: 4px;
        margin-bottom: 4px;
        width: 154px;
        box-sizing: border-box; /* Da se padding i border ne računaju dodatno u širinu */
    }
    .flex-center-mobile-shrink {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 4px 0px 4px 0px;
    }
    .flex-center-mobile {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 4px 0px 4px 0px;
    }
    .flex-center-mobile-wrap {
        flex-wrap: wrap;
        // justify-content: space-between;
        margin: 4px 0px 4px 0px;
    }
    .household-room-header {
        width: 100%;
        padding-left: 4px;
        padding-right: 4px;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 4px 0px 4px 0px;
        box-shadow: inset -1px -1px 10px 5px rgba(105, 119, 140, 0.15);
    }
    .wrap-item {
        min-width: calc(48% - 4px); /* 10px razmak između elemenata */
        box-sizing: border-box; /* Uključuje razmak i granicu u ukupnu širinu */
        margin: 4px;
    }
    .overflow-y-mobile {
        overflow-y: scroll;
    }

    .flex-center-end-mobile {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 4px 0px 4px 0px;
    }
    .width-25 {
        width: 100%;
    }
    .width-75 {
        width: 100%;
    }
    .align-self-auto-mobile {
        align-self: flex-start;
    }
    .page-title {
        padding-bottom: 16px;
    }
    .title-with-options {
        min-height: 40px;
    }

    .household-manager-wrapper {
        flex-direction: column;
        justify-content: flex-start;
        .custom-list-header,
        .custom-list-item {
            min-width: unset;
            margin-left: 4px !important;
            margin-right: 4px !important;
            margin-top: 4px !important;
        }
    }
    .household-select-tasks {
        .custom-list-header,
        .custom-list-item {
            min-width: unset;
            margin-left: 8px !important;
            margin-right: 8px !important;
            margin-top: 8px !important;
        }
    }

    .household-rooms-column {
        display: flex;
        flex-wrap: wrap;
        height: unset;
        overflow-y: unset;
        padding-left: 4px;
        padding-right: 4px;
        justify-content: center;
    }
    .household-task-templates {
        display: unset;
        justify-content: unset;
    }
    .household-room {
        margin-right: 4px;
        margin-top: 4px;
        //    height: 140px;
        width: 154px;
        box-sizing: border-box;
    }
    .household-grouped-rooms-manager {
        // border: 1px solid #abcaf8;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 8px;
        margin-bottom: 8px;
        position: relative;
        box-sizing: border-box;
        bottom: 8px;
        min-width: unset;
        flex: unset;
    }

    .household-grouped-rooms-maid {
        margin-right: 4px;
        margin-top: 4px;
        width: 100%;
        overflow-y: hidden;
    }
    .household-room-info {
        position: relative;
        // border: 1px solid #69778c;
        // margin-right: 8px;
        // margin-left: 8px;
        // margin-top: 16px;
        // width: 120px;
        // height: 120px;
    }
    .household-room-card {
        width: 100%;
        margin: auto;
        border: unset;
        // height: 75vh;
        position: relative;
    }
    .household-main {
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 8px;
    }
    .janitor-wrapper {
        display: block;
        .custom-list-header,
        .custom-list-item {
            min-width: unset;
            margin-left: 8px !important;
            margin-right: 8px !important;
            margin-top: 8px !important;
        }
    }
    .janitor-filters {
        flex: 1;
        max-width: unset;
    }
    .janitor-main {
        flex: 1;
        max-width: unset;
    }
    .day-picker-mobile {
        margin-bottom: 8px;
    }
    .household-card-reservation-info {
        display: unset;
        justify-content: unset;
    }
    .flex-center-mobile-grid {
        display: grid;
    }
}

/* Stilovi za pejzažni prikaz (landscape) */
@media (max-height: 780px) {
    .room-consumption-record {
        height: 47vh !important;
    }
    .household-message-list {
        height: 33vh !important;
    }
    /* Dodajte ovdje stilove za prikaz u pejzažnom načinu rada (landscape) */
}
@media (max-height: 600px) {
    .room-consumption-record {
        height: 35vh !important;
    }
    .household-message-list {
        height: 24vh !important;
    }
    // .household-grouped-rooms-wrapper {
    //     margin-top: 16px;
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: space-between;
    //     overflow-y: scroll;
    //     max-height: 60vh;
    // }
    // .household-rooms-column {
    //     margin-top: 8px;
    //     display: flex;
    //     flex-wrap: wrap;
    //     max-height: 60vh;
    //     overflow-y: scroll;
    //     justify-content: space-between;
    // }
    /* Dodajte ovdje stilove za prikaz u pejzažnom načinu rada (landscape) */
}

/* Stilovi za portretni prikaz (portrait) */
@media (max-width: 1180px) {
    // .household-grouped-rooms-manager {
    //     display: flex;
    //     position: relative;
    //     box-sizing: border-box;
    //     bottom: 8px;
    //     flex: 1 0 calc(90%);
    //     margin-left: 16px;
    // }
}

.custom-list-item-print {
    .custom-list-item {
        // overflow: hidden;
        padding: 12px;
        font-size: 14px;
        min-height: unset;
        height: unset;
        &:hover {
            background-color: transparent;
        }
    }
}
.custom-list-header-print {
    .custom-list-header {
        padding: 16px;
        padding-bottom: 4px;
        border-bottom: 1px solid $neutralColor700;

        // overflow: hidden;
        font-size: 14px;
        min-height: unset;
        height: unset;
        &:hover {
            background-color: transparent;
        }
    }
}
.border-right-print {
    border-right: 1px solid $neutralColor700;
    border-radius: 0%;
}
.border-bottom-print {
    border-bottom: 1px solid $neutralColor700;
    border-radius: 0%;
}
.noselect {
    user-select: none;
}

.scroll-container {
    height: 98vh;
    width: 100%;
    overflow-x: scroll;
    border: 1px solid #ccc;
}

.scroll-content {
    display: flex;
    white-space: nowrap;
    position: relative;
    height: 100%;
    width: 100%;
}

.sidebar {
    width: 200px;
    background-color: #f0f0f0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    // overflow-y: auto;
    padding: 10px;
}

.gl-content {
    display: inline-block;
    white-space: nowrap;
    display: inline-block;
    position: absolute;
    left: 0;
}

table {
    border-collapse: collapse;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.border-black {
    border-color: black !important;
}

.border-right-black {
    border-right: 1px solid black !important;
}

.border-bottom-black {
    border-bottom: 1px solid black !important;
}

.gl-report-header-wrapper-border {
    display: flex;
    border: 1px solid #000 !important;
    text-align: center;
}

.gl-report-header-wrapper {
    display: flex;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 100%;
}

.gl-report-header-right-border {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid black !important;
    padding: 10px 0px 10px 0px;
}

.text-left {
    justify-content: flex-start !important;
    padding-left: 5px;
}

.rc-date-picker-input-custom {
    input {
        background: #ffffff;
        border: 1px solid #d8e0ec;
        border-radius: 6px;
        height: 32px;
        width: 120px;
        padding: 8px 16px;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
}

.selection-tabs-single {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $neutralColor700;
    border-bottom: 1px solid $primaryColor100;
    cursor: pointer;
    padding: 0px 8px 0px 8px;
}

.selection-active-tab {
    color: $primaryColor700;
    border-bottom: 1px solid $primaryColor500;
    // font-weight: bold;
}

.h-w-100 {
    height: 100%;
    width: 100%;
}

.w-100 {
    width: 100%;
}

.w-8 {
    width: 8%;
}

.w-16 {
    width: 16%;
}

.w-20 {
    width: 20%;
}

.w-10 {
    width: 10%;
}

.w-30 {
    width: 30%;
}

.w-50 {
    width: 50%;
}

.w-70 {
    width: 70%;
}

.w-65 {
    width: 65%;
}

.doc-scan-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 100px;
}

.doc-scan-inner-wrapper {
    background-color: $UI-50;
    width: 480px;
    min-width: 480px;
    max-width: 480px;
    max-height: 80vh;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-radius: 6px;
}

.docScan-bg {
    background-color: #edf0f3;
}

.docScanner-background {
    border: 0;
    top: 0;
    bottom: 0px;
    left: 0;
    position: fixed;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.doc-scan-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    svg {
        height: 50px;
        width: 50px;
    }
}

.keyboard-style {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
    height: 90px;
    width: 90px;
    border: 1px solid $neutralColor100;
    border-radius: 10px;

    span {
        @include regular20Font();
    }
}

.doc-scan-foother {
    display: flex;
    position: absolute;
    align-items: flex-end;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    color: $neutralColor300;
}

.sm-rectangle {
    height: 7px;
    border-radius: 10px;
    border: 1px solid $neutralColor100;
    width: 30px;
    margin-right: 4px;
}

.card-scan-img {
    background: url('../img/card-scan.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
    width: 150px;
}

.select-guest-data {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    // max-height: 500px;
    // overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    margin-left: 8px;
}

.one-scan-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $neutralColor100;
    border-radius: 6px;
    padding: 5px 10px;
    width: 300px;
    cursor: pointer;
}

.scanned-doc-scroll {
    width: 450px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    max-height: 240px;
    overflow-y: auto;
}

.line-height-40 {
    line-height: 40px;
}

.justify-right {
    justify-content: right !important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.scan-other-guests {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 450px;
}

.sidebarDailyInfo {
    display: flex;
    flex-direction: column;
    padding-bottom: 13px;
    margin-bottom: 7px;
    border: 1px solid $neutralColor100;
    border-radius: 10px;
}
.color-success-dark {
    color: $sucessColor900;
    svg path {
        fill: $sucessColor900;
    }
}

.custom-control-input:checked ~ .custom-control-label::before {
    background: $primaryColor500 !important;
}

.src-bind-options > div {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 8px;
}
.src-bind-options {
    justify-content: space-between;
}

.condo-reservations {
    width: 25%;
    margin: 10px 15px 10px 15px;
    max-height: 700px;
    overflow-y: scroll;
}

.condo-occupancy {
    width: 70%;
    margin: 10px 15px 10px 15px;
    max-height: 700px;
    overflow-y: scroll;
}

.display-mobile {
    display: none;
}

.room-consumption-record {
    margin-top: 16px;
    overflow-y: scroll;
    height: 57vh;
}

.household-message-list {
    margin-top: 16px;
    overflow-y: scroll;
    height: 43vh;
}

.print-dark {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    svg {
        width: 40px;
        height: 40px;

        path {
            fill: black;
        }
    }
}

.print-title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: $UI-900;
    margin-bottom: 10px;
}

.print-date {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    color: $UI-900;
    margin-bottom: 10px;
}

.print-account-data {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 10px 0 10px 0;
}

.account-report-header {
    display: flex;
    font-weight: bold;
    height: auto;
    font-size: 14px;
    background-color: $UI-50;
    border-bottom: 1px solid $neutralColor100;
    margin-top: 16px;
    margin-left: auto;
    margin-right: 30px;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
}

.account-report-items {
    display: flex;
    height: auto;
    font-size: $standardFontSize;
    border-bottom: 1px solid $neutralColor100;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: auto;
    margin-right: 30px;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    word-break: break-word;
    &:hover {
        background-color: $neutralColor50;
    }
}

.web-blogs-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50%;
}

.web-blogs {
    display: flex;
    align-items: center;
    width: 40%;
    min-height: 100px;
    max-height: 200px;
    background-color: white;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: #f0f6ff;
    cursor: pointer;
}

.blog-img {
    width: 47%;
    left: 0;

    img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

.blog-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 50%;
    margin-left: 8px;
    margin-right: 8px;
}

.blog-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    height: 30%;
    margin-bottom: 10px;
    text-align: left !important;
    width: 100%;
}

.blog-description {
    @include regular16Font();
    font-weight: normal !important;
    line-height: 24px;
    height: 70%;
    text-align: left !important;
    width: 100%;
}

.visit-website {
    margin-top: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: right;

    &:hover {
        text-decoration: underline;
    }
}

.carousel-out-wrapper {
    position: absolute;
    top: 39%;
    right: 50%;
    border-radius: 20px;
}

.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 85%;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 20px;
    color: white;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    border-radius: 20px;
    background-color: #4d4d4d7a;
    border-radius: 20px;
}

.carousel-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    border-radius: 20px;
}

.carousel-slide {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4d4d4d7a;
    height: 220px;
    border-radius: 20px;
    cursor: pointer;
}

.carousel-button {
    position: absolute;
    top: 42%;
    // transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    border: none;
    font-size: 12px;
    cursor: pointer;
    padding: 10px;
    z-index: 1;
    border-radius: 10px;
}

.prev {
    left: 2%;
}

.next {
    right: 2%;
}

.companies-list {
    max-height: 300px;
    overflow-y: scroll;
    z-index: 99999;
    position: absolute;
}

.list-wrapper-for-scroll {
    position: relative;
    flex: auto;
    overflow: scroll;
}

.wrapper-no-scroll {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.position-sticky-header {
    position: sticky;
    top: 0;
}

.first-letter-uppercase::first-letter {
    text-transform: uppercase;
}

.licenceWarning {
    background-color: #fe5757;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    text-align: left;
    font-size: 15px;
    margin-top: 10px;
    margin-right: 5px;
}

.calc-table {
    font-size: 14px !important;
}

.calc-table table {
    width: 100%;
    // border-collapse: collapse;
    margin: 20px 0;
    font-family: Arial, sans-serif;
    font-size: 12px !important;
}

.calc-table th,
.calc-table td,
.calc-table tr {
    padding: 4px;
    text-align: left;
    border: 1px solid black !important;
}

.calc-table table td {
    max-width: 200px;
    flex-wrap: wrap;
}

.calc-table th,
.calc-table th:first-child {
    background-color: $neutralColor100;
    color: $UI-900;
}

.calc-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.calc-table tr:hover {
    background-color: $neutralColor50;
}

@media print and (orientation: portrait) {
    .calc-table th,
    .calc-table td{
        font-size: 14px !important;
    }
}

@media print and (orientation: landscape) {
    .calc-table th,
    .calc-table td{
        font-size: 10px !important;
    }
}

@media print {
    .calc-table th,
    .calc-table td
    // .calc-table tr
    {
        border: 1px solid black !important;
        font-weight: normal !important;
        word-wrap: break-word;
        max-width: 70px;
    }

    .calc-table table {
        border-collapse: collapse !important;
        
    }

    .calc-table {
        width: 100%; /* Postavlja tablicu da koristi cijelu širinu stranice */
    }

    .code-width {
        min-width: 50px !important;
        width: 50px !important; /* Dodajte !important za nadjačavanje ostalih pravila */
        max-width: 50px !important;
        word-wrap: break-word;
    }

    .item-width {
        min-width: 170px !important;
        width: 170px !important; /* Dodajte !important za nadjačavanje ostalih pravila */
        max-width: 170px !important;
        word-wrap: break-word;
    }

    .item-width-lg {
        min-width: 250px !important;
        width: 250px !important; /* Dodajte !important za nadjačavanje ostalih pravila */
        max-width: 250px !important;
        word-wrap: break-word;
    }

    .td-60-width {
        min-width: 60px !important;
        word-wrap: break-word;
    }

    .td-70-width {
        min-width: 70px !important;
        word-wrap: break-word;
    }

    tr .text-bold {
        td {
            font-weight: bold !important;
        }
    }
}
